<template>
  <!-- 司机派单统计 -->
  <div ref="distributionStatistics" class="distributionStatistics">
    <div ref="ActionBar" class="ActionBar">
      <div class="actionBar-box">
        <div class="col-1">派单时间段：</div>
        <div class="col-2">
          <el-date-picker
            v-model="timeBucketValue"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">业务类型：</div>
        <div class="col-2 col-2-a">
          <el-select
            v-model="tableFrom.operationMode"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in businessTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">所属企业：</div>
        <div class="col-2 col-2-a">
          <companySelect
            :clearable="true"
            :company-tree="companyList"
            :valuename="valueAddName"
            :value="valueAdd"
            @getValue="getAddGroupId"
          ></companySelect>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">司机姓名：</div>
        <div class="col-2 col-2-a">
          <el-input v-model="tableFrom.driverName" clearable></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <el-button type="primary" size="small" @click="queryFun"
          >查询</el-button
        >
        <el-button
          v-if="listFind('导出')"
          type="primary"
          size="small"
          @click="tableExport"
          >导出</el-button
        >
      </div>
    </div>
    <Table
      :table-height="TableHeight"
      :col-class-type="true"
      :table-data="tableData"
      :title-name="titleName"
    ></Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="tableFrom.pageSize"
        :current-page="tableFrom.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import companySelect from "@/components/treeSelect/companySelect.vue";
import {
  getCompanyTree,
  driverStatisticsAPI,
  driverExportAPI,
} from "@/api/lib/api.js";
export default {
  components: {
    companySelect,
  },
  data() {
    return {
      TableHeight: 0,
      businessTypeList: [
        { value: 1, label: "城际专车" },
        { value: 4, label: "包车" },
      ],
      companyList: [],
      valueAddName: null,
      valueAdd: null,
      tableData: [],
      titleName: [
        {
          title: "统计时间段",
          props: "timeBucket",
          width: "200",
        },
        {
          title: "公司",
          props: "companyName",
        },
        {
          title: "业务类型",
          props: "operationMode",
          SpecialJudgment: (res) => {
            return res == 4 ? "包车" : res == 1 ? "城际专车" : "";
          },
        },
        {
          title: "司机身份证号码",
          props: "idCardNo",
          width: "150",
        },
        {
          title: "司机姓名",
          props: "driverName",
        },
        {
          title: "派单数量",
          props: "assignOrderNumber",
        },
        {
          title: "派单乘客人数",
          props: "assignOrderPassengerNumber",
        },
        {
          title: "有效乘客人数",
          props: "assignOrderPassengerValidNumber",
        },
        {
          title: "无效乘客人数",
          props: "assignOrderPassengerNoValidNumber",
        },
      ],
      tableFrom: {
        pageSize: 10,
        currentPage: 1,
        companyIds: "",
        operationMode: 1,
        timeBucket: "",
        driverName: "",
      },
      total: 0,
      timeBucketValue: "",
    };
  },
  created() {
    this.getCompanyList();
    this.renderData();
    let myDate = new Date();
    this.timeBucketValue = [];
    myDate.setMonth(myDate.getMonth() - 1);
    this.timeBucketValue[0] =
      myDate.getFullYear() +
      "-" +
      (myDate.getMonth() + 1) +
      "-" +
      myDate.getDate();
    myDate.setMonth(myDate.getMonth() + 1);
    this.timeBucketValue[1] =
      myDate.getFullYear() +
      "-" +
      (myDate.getMonth() + 1) +
      "-" +
      myDate.getDate();
    this.tableFrom.timeBucket =
      this.GMTToStr(this.timeBucketValue[0]).replace(/-/g, "/") +
      "-" +
      this.GMTToStr(this.timeBucketValue[1]).replace(/-/g, "/");
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
  methods: {
    // 点击导出按钮
    tableExport() {
      driverExportAPI(this.tableFrom).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "派单统计表 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");
        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    // 点击查询按钮
    queryFun() {
      if (this.timeBucketValue) {
        this.tableFrom.timeBucket =
          this.GMTToStr(this.timeBucketValue[0]).replace(/-/g, "/") +
          "-" +
          this.GMTToStr(this.timeBucketValue[1]).replace(/-/g, "/");
        this.tableFrom.currentPage = 1;
        this.tableFrom.pageSize = 10;
        this.renderData();
      } else {
        this.$message.error("请选择派单时间段！");
        this.tableFrom.timeBucket = "";
      }
      let arr = [];
      for (let i in this.tableFrom) {
        let obj = {};
        obj[i] = this.tableFrom[i];
        arr.push(obj);
      }
    },
    // 计算表格高度
    computeHeight() {
      const boxHeight = this.$refs["distributionStatistics"].clientHeight;
      const topHeight = this.$refs["ActionBar"].clientHeight;
      this.TableHeight = boxHeight - topHeight - 90;
    },
    // 获取列表数据Fun
    renderData() {
      driverStatisticsAPI(this.tableFrom).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 企业回显
    getAddGroupId(v) {
      if (v == null) {
        this.tableFrom.companyIds = [];
      } else {
        this.tableFrom.companyIds = [v];
      }
    },
    // 获取企业树
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.tableFrom.currentPage = page;
      this.renderData();
    },
    // 当前条数改变
    onSizeChange(size) {
      this.tableFrom.pageSize = size;
      this.tableFrom.currentPage = 1;
      this.renderData();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-range-separator {
  line-height: 26px !important;
}
.distributionStatistics {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  background: #ffffff;
  height: calc(100% - 40px);
  .ActionBar {
    background: #f7f8f9;
    padding: 16px;
    padding-bottom: 0;
    display: flex;
    margin-bottom: 16px;
    flex-flow: wrap;
    .actionBar-box:nth-child(4n) {
      margin-right: 0;
    }
    .actionBar-box:after {
      content: "";
      flex: auto;
    }
    .actionBar-box {
      width: calc(25% - 18px);
      height: 32px;
      line-height: 32px;
      margin-right: 7px;
      margin-bottom: 16px;
      padding-right: 10px;
      .col-1-a {
        width: 70px !important;
      }
      .col-1 {
        width: 85px;
        display: inline-block;
      }
      .col-2-a {
        width: calc(100% - 70px) !important;
      }
      .col-2 {
        display: inline-block;
        width: calc(100% - 85px);
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>
